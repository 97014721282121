<template>
  <div class="CrawlingTimeList" >

    <!-- ERROR MODAL -->
    <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal"  hide-footer title="Une erreur s'est produite">
      <div class="text-center">
        <p>Une erreur s'est produite, veuillez actualiser la page et réessayer</p>
        <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
      </div>
    </b-modal>

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="#F9BF3B">
    </loading>

    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Temps de crawl</h1>
        <div class="form_div">
          <div class="form_content">
            <div class="row">
              <div class="align-self-center col-12">
                <strong> Liste des temps de crawls pour chaque page</strong>
                <p>
                  Attention, les crawls étant séparés (pdf, selenium, etc.), ce temps n'est pas tout à fait
                  exact mais permet de donner un ordre de grandeur des temps de crawls de chaque page
                </p>
                <p>
                  <strong>Méthodologie :</strong><br>
                  A chaque fois qu'on crawle une page
                  <ul>
                    <li>via HTML : on écrase le temps de crawl précédent</li>
                    <li>via selenium : on écrase le temps de crawl précédent</li>
                    <li>en pdf (on parse un pdf de la page crawlée) : on ajout le temps de crawl au temps précédent</li>
                  </ul>
                </p>
              </div>
            </div>
            <b-table
              responsive class="align-middle text-center mt-2"
              headVariant="light"
              :fields="pagesRenderFields"
              :items="pages"
            >
              <template v-slot:cell(crawling_time)="data">
                {{minutesAndSeconds(data.item.crawling_time)}}
              </template>
          </b-table>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import { APIPage } from '@/api/APIPage'
import formTitleComponent from '@/components/formTitleComponent'
import renderMixins from '@/mixins/renderMixins'

const apiPage = new APIPage()

export default {
  name: 'PageCrawlingTimeList',
  components: {
    formTitleComponent,
    Loading
  },
  mixins: [
    renderMixins,
  ],
  data: function () {
    return {
      pages: [],
      pagesRenderFields: [
        { key: "url", label: "Url", tdClass: 'ui-helper-center', sortable: true},
        { key: "crawling_time", label: "Minutes", tdClass: 'ui-helper-center', sortable: true},
      ],
      isCrawlingTimesLoading: true,

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isCrawlingTimesLoading) {
        return true
      }
      return false
    },
  },
  created: function() {
    this.$dayjs.locale('fr')
    this.getAllPages()
  },
  methods: {
    // ------------- GETTERS ------------------
    getAllPages () {
      this.isCrawlingTimesLoading = true
      apiPage.getAllPages(this.token)
      .then((result) => {
        this.pages = result.data
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('CrawlingTimeList getAllPages API Error : ' + String(error))
      })
      .finally(() => {
        this.isCrawlingTimesLoading = false
      })
    },
  }
}
</script>
